<div class="panel-block">
    <p class="control">
        <p-slider [(ngModel)]="rangeValues" (ngModelChange)="updateFilterChange()" [range]="true" [max]="this.filter.maxValue || 0"
            [min]="this.filter.minValue || 0" [disabled]="!this.filter.maxValue && !this.filter.minValue">
        </p-slider>
    </p>
</div>
<div class="panel-block">
    <div class="level">
        <div class="level-item">
            Range: {{rangeValues[0] + ' - ' + rangeValues[1]}}
        </div>
    </div>
</div>
