<h2 class="subtitle">
    <div class="level">
        <div class="level-item level-left" grtSubtitle>
            Parsed Tree
        </div>
        <div class="level-item level-right is-hidden-print">
            <p class="control">
                <a class="button" role="button" (click)="display = 'both'" grtBalloonPosition="left"
                    grtBalloon="Full screen display of the tree.">
                    <span class="icon is-large">
                        <fa-icon [icon]="faExpand" aria-hidden="true"></fa-icon>
                    </span>
                </a>
            </p>
        </div>
    </div>
</h2>
<p *ngIf="loading">Loading ...</p>
<ng-container *ngIf="xml">
    <grt-tree-visualizer [xml]="xml" [display]="display" [fullScreenButton]="false"
        (displayChange)="display = $event"></grt-tree-visualizer>
</ng-container>
<br />
<div class="columns">
    <div class="column content">
        <p>You find the structure of the POS-tagged and parsed sentence above. POS-tagging refers to the annotation of
            <em>word classes</em>, such as
            <code>n</code> (noun), and parsing refers to the annotation of
            <em>dependency relations</em>, such as
            <code>su</code> (subject), and
            <em>constituents</em>, such as
            <code>np</code> (noun phrase).
        </p>
    </div>
    <div class="column content">
        <p>This parse is based on the sentence:</p>
        <code class="is-family-primary is-size-5">{{sentence}}</code>
        <div *ngIf="warning" class="notification is-danger break-word">{{warning}}</div>
    </div>
</div>
