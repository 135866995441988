<div class="box">
    <article class="media">
        <div class="media-left">
            <a href="{{href}}" grtBalloonPosition="down-left" grtBalloon="Download Example-Based Treebank Querying" target="_blank">
                <span class="stack">
                    <fa-icon [icon]="faDownload" aria-hidden="true"></fa-icon>
                </span>
                <span class="sr-only">Download Example-Based Treebank Querying</span>
            </a>
        </div>
        <div class="media-content">
            <ng-content></ng-content>
        </div>
    </article>
</div>
