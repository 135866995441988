<grt-body-header>Example-based Search</grt-body-header>
<grt-breadcrumb-bar [crumbs]="crumbs" [currentCrumb]="globalState?.currentStep?.number" (jump)="goToStep($event)"></grt-breadcrumb-bar>
<div [ngSwitch]="globalState?.currentStep?.number" [ngClass]="{'is-loading':isTransitioning}">
    <grt-sentence-input
        *ngSwitchCase="0"

        [inputSentence]="globalState.inputSentence"

        (changeValid)="setValid($event)"
        (changeValue)="updateSentence($event)"
        (next)="next()">
    </grt-sentence-input>
    <grt-parse
        *ngSwitchCase="1"

        [sentence]="globalState.inputSentence"
        [xml]="globalState.exampleXml"
        [loading]="globalState.loading"

        (changeValid)="setValid($event)">
    </grt-parse>
    <grt-matrix
        *ngSwitchCase="2"
        (changeValid)="setValid($event)"
        (changeValue)="updateMatrix($event)">
    </grt-matrix>
    <grt-select-treebanks
        *ngSwitchCase="3"

        (changeValid)="setValid($event)"
        (prev)="prev()"
        (next)="next()">
    </grt-select-treebanks>
    <grt-results
        *ngSwitchCase="4"

        [filterValues]="globalState.filterValues"
        [inputSentence]="globalState.inputSentence"
        [retrieveContext]="globalState.retrieveContext"

        (changeValid)="setValid($event)"
        (changeXpath)="updateXPath($event)"
        (changeRetrieveContext)="updateRetrieveContext($event)"
        (changeFilterValues)="updateFilterValues($event)"
        (prev)="prev()"
        (next)="next()">
    </grt-results>
    <grt-analysis
        *ngSwitchCase="5"

        [xpath]="globalState.xpath"

        (changeValid)="setValid($event)"
        (filterResults)="filterResults($event, 4)">
    </grt-analysis>
</div>
<br />
<div *ngIf="warning" class="notification is-danger warning">{{warning}}</div>
<grt-step-buttons *ngIf="globalState?.currentStep?.number > 0"
    (prev)="prev()" (next)="next()" [currentStep]="globalState?.currentStep?.number" [steps]="crumbs.length"></grt-step-buttons>
