<h2 class="subtitle" grtSubtitle>
    XPath Query
</h2>
<grt-xpath-editor width="100%" [value]="value" (onChange)="inputChanged($event)" autofocus="true"></grt-xpath-editor>
<div class="field">
    <label class="checkbox">
        <input type="checkbox" name="retrieveContext" [(ngModel)]="retrieveContext" (change)="emitRetrieveContextChanged()"> Include context in results
        <label grtBalloonLength="large" grtBalloonPosition="right" grtBalloon="In the results, show the sentence before and after the matching sentence to provide a broader context">
            <fa-icon [icon]="faInfoCircle" aria-hidden="true"></fa-icon>
        </label>

    </label>
</div>
<ng-container *ngIf="treeXml">
    <h2 class="subtitle">
        <div class="level">
            <div class="level-item level-left">
                Tree&nbsp;
                <label grtBalloonLength="medium" grtBalloonPosition="right" grtBalloon="This is a visualization of the entered query.">
                    <fa-icon [icon]="faInfoCircle" aria-hidden="true"></fa-icon>
                </label>

            </div>
            <div class="level-item level-right is-hidden-print">
                <p class="control">
                    <a class="button" role="button" (click)="treeDisplay = 'both'" grtBalloonPosition="left" grtBalloon="Full screen display of the tree.">
                        <span class="icon is-large">
                            <fa-icon [icon]="faExpand" aria-hidden="true"></fa-icon>
                        </span>
                    </a>
                </p>
            </div>
        </div>
    </h2>
    <grt-tree-visualizer [xml]="treeXml" [display]="treeDisplay" [fullScreenButton]="false" (displayChange)="treeDisplay = $event"></grt-tree-visualizer>
</ng-container>
