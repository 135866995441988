<grt-body-header>XPath Search</grt-body-header>
<grt-breadcrumb-bar [crumbs]="crumbs" [currentCrumb]="globalState?.currentStep?.number" (jump)="goToStep($event)"></grt-breadcrumb-bar>
<div [ngSwitch]="globalState?.currentStep?.number" [ngClass]="{'is-loading':isTransitioning}">
    <grt-xpath-input
        *ngSwitchCase="0"

        [retrieveContext]="globalState.retrieveContext"
        [value]="globalState.xpath"

        (changeValid)="setValid($event)"
        (changeValue)="updateXPath($event, false)"
        (changeRetrieveContext)="updateRetrieveContext($event)">
    </grt-xpath-input>
    <grt-select-treebanks
        *ngSwitchCase="1"

        (changeValid)="setValid($event)"
        (prev)="prev()"
        (next)="next()">
    </grt-select-treebanks>
    <grt-results
        *ngSwitchCase="2"

        [filterValues]="globalState.filterValues"
        [inputSentence]="globalState.inputSentence"
        [retrieveContext]="globalState.retrieveContext"

        (changeValid)="setValid($event)"
        (changeXpath)="updateXPath($event, 'history')"
        (changeRetrieveContext)="updateRetrieveContext($event)"
        (changeFilterValues)="updateFilterValues($event)"
        (prev)="prev()"
        (next)="next()">
    </grt-results>
    <grt-analysis
        *ngSwitchCase="3"

        [xpath]="globalState.xpath"

        (changeValid)="setValid($event)"
        (filterResults)="filterResults($event, 2)">
    </grt-analysis>
</div>
<br />
<div *ngIf="warning" class="notification is-danger warning">{{warning}}</div>
<grt-step-buttons (prev)="prev()" (next)="next()" [currentStep]="globalState?.currentStep?.number" [steps]="crumbs.length"></grt-step-buttons>
