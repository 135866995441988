 <h2 class="subtitle">
    <div class="level">
        <div class="level-item level-left">
            Components
        </div>
        <div class="level-item level-right is-hidden-print">
            <div class="field">
                <p class="control" grtBalloonPosition="down-right" grtBalloon="Download distribution list (.csv)">
                    <a class="button" [ngClass]="{'is-loading': loading}" (click)="download()">
                        <span class="icon is-large">
                            <fa-icon [icon]="faDownload" aria-hidden="true"></fa-icon>
                        </span>
                    </a>
                </p>
            </div>
        </div>
    </div>
</h2>
<table class="table is-fullwidth scroll has-text-left">
    <thead>
        <tr>
            <th>
                <!-- disabled checkbox to align precisely with the other checkboxes-->
                <label class="checkbox">
                    <input type="checkbox" style="visibility: hidden" disabled />
                </label>
                Name
            </th>
            <th>Hits</th>
            <th>All Sentences</th>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngFor="let provider of state | keyvalue">
            <ng-container *ngFor="let corpus of provider.value | keyvalue">
                <tr>
                    <th>
                        <label class="checkbox">
                            <input
                                type="checkbox"
                                [checked]="!corpus.value.hidden"
                                (change)="toggleAllComponents(provider.key, corpus.key, !corpus.value.hidden)"
                            /> {{corpus.key}}
                        </label>
                    </th>
                    <th>{{corpus.value.hits != null ? corpus.value.hits : 'loading...'}}</th>
                    <th>{{corpus.value.sentenceCount}}</th>
                </tr>
                <ng-container *ngIf="!corpus.value.hidden">
                    <tr *ngFor="let component of corpus.value.components | keyvalue">
                        <td>
                            &nbsp; &nbsp; <label class="checkbox">
                                <input
                                    type="checkbox"
                                    [checked]="!component.value.hidden"
                                    (change)="toggleComponent(provider.key, corpus.key, component.key, !component.value.hidden)"
                                /> {{component.value.title}}
                            </label>
                        </td>
                        <td>{{component.value.hits != null ? component.value.hits : 'loading...'}}</td>
                        <td>{{component.value.sentenceCount}}</td>
                    </tr>
                </ng-container>
                <tr></tr>
            </ng-container>
        </ng-container>
    </tbody>
    <tfoot>
        <tr>
            <td></td>
            <td>{{totalHits.toLocaleString()}}</td>
            <td>{{totalSentences}}</td>
        </tr>
    </tfoot>
</table>
