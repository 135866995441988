<div class="columns is-desktop">
    <!-- XPATH -->
    <div class="column is-half-desktop">
        <grt-results-xpath-editor [xpath]="xpath"
            [activeFilterCount]="activeFilterCount"
            [inputSentence]="inputSentence"
            (changeXpath)="changeXpath.emit($event)"
            (addFiltersXPath)="addFiltersXPath()"></grt-results-xpath-editor>
    </div>
    <!-- DISTRIBUTION LIST -->
    <div class="column">
        <grt-distribution-list (hidingComponents)="hideComponents($event)"></grt-distribution-list>
    </div>
</div>
<grt-results-table
    [loading]="loading"
    [loadingDownload]="loadingDownload"
    [filteredResults]="filteredResults"
    [filterXPaths]="filterXPaths"
    [retrieveContext]="retrieveContext"
    (changeFilterValues)="filterChange($event)"
    (deleteFilter)="deleteFilter($event)"
    (downloadResults)="downloadResults($event)"
    (downloadFilelist)="downloadFilelist()"
    (toggleContext)="toggleContext()"
    (prev)="prev.next()"
    (next)="next.next()"
    (showTree)="showTree($event)"
></grt-results-table>
<grt-tree-visualizer *ngIf="loadingTree || treeXml" display="fullscreen" [sentence]="treeSentence" [xml]="treeXml"
    [filename]="treeFilename" [loading]="loadingTree"></grt-tree-visualizer>
