<div class="navbar-item has-dropdown" [ngClass]="{ 'is-active': active }" *ngIf="allowLogin">
    <a class="navbar-link is-arrowless" (click)="toggleActive()">
        <ng-container *ngIf="user">
            <span class="icon">
                <fa-icon [icon]="faUser" aria-hidden="true"></fa-icon>
            </span>
            <span>
                {{ user.displayName }}
            </span>
        </ng-container>
        <ng-container *ngIf="!user">
            <span class="icon">
                <fa-icon [icon]="faUsers" aria-hidden="true"></fa-icon>
            </span>
            <span>
                Login
            </span>
        </ng-container>
    </a>

    <div class="navbar-dropdown is-right">
        <a class="navbar-item" *ngIf="user" [ngClass]="{'is-disabled': loading}" (click)="logout()">
            Logout
        </a>

        <form [formGroup]="loginForm" (ngSubmit)="login()" *ngIf="!user">
            <div class="navbar-item">
                <div class="field">
                    <label class="label">Username</label>
                    <div class="control">
                        <input class="input" type="text" placeholder="Username" required formControlName="username">
                    </div>
                </div>
            </div>
            <div class="navbar-item">
                <div class="field">
                    <label class="label">Password</label>
                    <div class="control">
                        <input class="input" type="password" placeholder="Password" required formControlName="password">
                    </div>
                </div>
            </div>
            <div class="navbar-item">
                <div class="field">
                    <div class="control">
                        <button class="button" type="submit"
                            [ngClass]="{'is-loading': loading, 'is-primary': !loading, 'is-danger':error}">
                            Login
                        </button>
                    </div>
                    <p class="help is-danger" *ngIf="error">Check your input</p>
                </div>
            </div>
        </form>
    </div>
</div>
