<div class="notification is-primary explanation" *ngIf="renderCount && showExplanation" [@slideDown]>
    <button class="delete" (click)="showExplanation = false"></button>
    <p>Drag a node from the <strong>XPath query</strong> or the <strong>tree</strong> into the analysis table below to
        analyse its properties.</p>
</div>
<div class="columns query" [ngClass]="{'disabled': disabled}">
    <div class="column" [ngClass]="{'is-half': treeXml}">
        <h2 class="subtitle">XPath</h2>
        <div class="content">
            <grt-xpath-viewer [value]="xpath" [variables]="variables"></grt-xpath-viewer>
        </div>
    </div>
    <div class="column" *ngIf="treeXml">
        <h2 class="subtitle">
            <div class="level">
                <div class="level-item level-left">
                    Tree
                </div>
                <div class="level-item level-right is-hidden-print">
                    <p class="control">
                        <a class="button" role="button" (click)="treeDisplay = 'both'" grtBalloonPosition="left"
                            grtBalloon="Full screen display of the tree.">
                            <span class="icon is-large">
                                <fa-icon [icon]="faExpand" aria-hidden="true"></fa-icon>
                            </span>
                        </a>
                    </p>
                </div>
            </div>
        </h2>
        <grt-tree-visualizer [xml]="treeXml" [display]="treeDisplay" [fullScreenButton]="false"
            (displayChange)="treeDisplay = $event"></grt-tree-visualizer>
    </div>
</div>
<div class="modal is-active" *ngIf="selectedVariable" [@slideDown]>
    <div class="modal-background"></div>
    <div class="modal-content">
        <div class="box" *ngIf="custom">
            <grt-node-properties (add)="addVariable($event)" [nodeName]="selectedVariable.variable.name">
            </grt-node-properties>
            <div class="level">
                <div class="level-left">
                    <a role="button" (click)="custom=false">
                        Show Existing Properties...
                    </a>
                </div>
                <div class="level-right">
                    <div class="buttons is-right">
                        <div class="button" role="button" (click)="cancelVariable()">
                            Cancel
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="box" *ngIf="!custom">
            <div class="content">
                Which property of
                <span class="tag">{{selectedVariable.variable.name}}</span> should be analyzed?
            </div>
            <grt-xpath-viewer [value]="selectedVariable.variable.path"></grt-xpath-viewer>
            <br />
            <div class="field">
                <ng-select [(ngModel)]="selectedVariable.attribute" required="true">
                    <ng-option *ngFor="let attribute of attributes" [value]="attribute.value">
                        {{attribute.label}}
                    </ng-option>
                </ng-select>
            </div>
            <div class="level">
                <div class="level-left">
                    <a role="button" (click)="custom=true">
                        Specify Custom Properties...
                    </a>
                </div>
                <div class="level-right">
                    <div class="buttons is-right">
                        <div class="button" role="button" (click)="cancelVariable()">
                            Cancel
                        </div>
                        <div class="button is-primary" role="button" (click)="addVariable()">
                            Add
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <button class="modal-close is-large" aria-label="close" (click)="cancelVariable()"></button>
</div>
<div class="level">
    <div class="level-left">
        <h1 class="title" [ngClass]="{'is-loading': isLoading}" grtSubtitle>Analysis Table</h1>&nbsp;
    </div>
    <div class="level-right">
        <div class="level-item" *ngIf="canShowMore" grtBalloon="More data is ready to be shown in the table">
            <a class="button has-badge-rounded" [ngClass]="{'has-badge-warning': isLoading}" role="button"
                (click)="showMore()" [attr.data-badge]="hitsCount - renderCount">
                Show More Data
            </a>
        </div>
    </div>
</div>
<div class="analysis-box" [ngClass]="{'box': renderCount}">
    <div class="content" [@slideDown] *ngIf="renderCount">
        <p>Click on a cell in the table to view those documents.</p>
    </div>
    <div class="analysis-component" [ngClass]="{'disabled': disabled}"></div>
</div>
