<ul class="fa-ul">

    <li>
        <span class="fa-li">
            <fa-icon [icon]="faAddressBook" aria-hidden="true"></fa-icon>
        </span>
        <address>
            Utrecht University
            <br> Drift 10, room 3.07
            <br> 3512 BS Utrecht
            <br> Netherlands
            <br>
        </address>
    </li>
    <li>
        <span class="fa-li">
            <fa-icon [icon]="faLink" aria-hidden="true"></fa-icon>
        </span>
        <a href="https://dig.hum.uu.nl" grtBalloonPosition="right" grtBalloon="Digital Humanities Lab homepage" target="_blank">Digital Humanities Lab</a>
    </li>
    <li>
        <span class="fa-li">
            <fa-icon [icon]="faPhone" aria-hidden="true"></fa-icon>
        </span>
        +31 30 253 7867
    </li>
    <li>
        <span class="fa-li">
            <fa-icon [icon]="faEnvelope" aria-hidden="true"></fa-icon>
        </span>
        <a href="mailto:s.j.j.spoel@uu.nl" grtBalloonPosition="right" grtBalloon="Email us">s.j.j.spoel@uu.nl</a>

    </li>
</ul>
