<div class="panel" *ngFor="let filter of this.filters">
    <ng-container *ngIf="filter">
        <p class="panel-heading">
            {{filter.field}}
            <span class="icon has-text-primary" *ngIf="filterValues[filter.field]">
                <fa-icon [icon]="faFilter" aria-hidden="true"></fa-icon>
            </span>
        </p>
        <ng-container [ngSwitch]="filter.filterType">
            <grt-text *ngSwitchCase="'checkbox'" [filter]="filter" [filterValue]="filterValues[filter.field]" (filterChange)="filterChanged($event)"></grt-text>
            <grt-int *ngSwitchCase="'slider'" [filter]="filter" [filterValue]="filterValues[filter.field]" (filterChange)="filterChanged($event)"></grt-int>
            <grt-date *ngSwitchCase="'range'" [filter]="filter" [filterValue]="filterValues[filter.field]" (filterChange)="filterChanged($event)"></grt-date>
            <grt-dropdown *ngSwitchCase="'dropdown'" [filter]="filter" [filterValue]="filterValues[filter.field]" (filterChange)="filterChanged($event)"></grt-dropdown>
        </ng-container>
    </ng-container>
</div>
