<div class="section" *ngIf="loading">
    <progress class="progress is-primary is-large" max="100">Loading...</progress>
</div>
<div *ngIf="!loading" [@slideDown]>
    <table class="table is-hoverable is-fullwidth">
        <thead>
            <tr>
                <th [attr.colspan]="variants ? variants.length : 1">
                    <label class="checkbox" *ngIf="treebank.multiOption">
                        <input type="checkbox" title="Select all" [checked]="allSelected"
                            (change)="toggleVariant(undefined)">
                        Component
                    </label>
                    <span *ngIf="!treebank.multiOption">Component</span>
                </th>
                <th *ngIf="showDescription">
                    Description
                </th>
                <ng-container *ngFor="let variant of (variants || [])">
                    <th class="has-text-right">
                        Sentences
                    </th>
                    <th class="has-text-right" *ngIf="showWordCount">
                        Words
                    </th>
                </ng-container>
                <th class="has-text-right">
                    Sentences
                </th>
                <th class="has-text-right" *ngIf="showWordCount">
                    Words
                </th>
            </tr>

            <tr *ngIf="variants">
                <!-- variant selector checkboxes -->
                <th *ngFor="let variant of variants">
                    <label *ngIf="treebank.multiOption" class="checkbox"
                        [attr.disabled]="!treebank.multiOption || null">
                        <input type="checkbox" [checked]="variant.selected" [disabled]="!treebank.multiOption || null"
                            (change)="toggleVariant(variant.name)">
                        {{variant.name}}
                    </label>
                    <label *ngIf="!treebank.multiOption">{{variant.name}}</label>
                </th>
                <th *ngIf="showDescription">
                    &nbsp;
                </th>
                <!-- Variant sentence/wordcount col headers -->
                <th *ngFor="let variant of variants" colspan="2" class="has-text-centered">
                    {{variant.name}}
                </th>
                <th colspan="2" class="has-text-centered">
                    Total
                </th>
            </tr>
        </thead>

        <tbody *ngIf="(!componentGroups || !variants) else withGroups">
            <tr *ngFor="let c of components | keyvalue" (click)="toggleComponent(c.value)">
                <!-- TODO move into named template -->
                <td>
                    <label [class]="treebank.multiOption ? 'checkbox' : 'radio'"
                        [attr.disabled]="c.value.disabled || null" (click)="$event.stopPropagation()">
                        <input [type]="treebank.multiOption ? 'checkbox' : 'radio'" [disabled]="c.value.disabled"
                            [checked]="c.value.selected" (change)="toggleComponent(c.value)">
                        {{c.value.title}}
                    </label>
                </td>

                <td *ngIf="showDescription">{{c.value.description}}</td>
                <td class="is-number">{{c.value.sentenceCount.toLocaleString()}}</td>
                <td class="is-number" *ngIf="showWordCount">{{c.value.wordCount.toLocaleString()}}</td>
            </tr>
        </tbody>
        <ng-template #withGroups>
            <tbody>
                <tr *ngFor="let group of componentGroups" (click)="toggleGroup(group)">
                    <td *ngFor="let variant of variants">
                        <label [class]="treebank.multiOption ? 'checkbox' : 'radio'"
                            [attr.disabled]="components[group.components[variant.name]].disabled || null"
                            (click)="$event.stopPropagation()">
                            <input [type]="treebank.multiOption ? 'checkbox' : 'radio'"
                                [disabled]="components[group.components[variant.name]].disabled"
                                [checked]="components[group.components[variant.name]].selected"
                                (change)="toggleComponent(components[group.components[variant.name]])">
                            {{components[group.components[variant.name]].title}}
                        </label>
                    </td>
                    <td *ngIf="showDescription">
                        {{group.description}}
                    </td>
                    <ng-container *ngFor="let variant of variants">
                        <td class="is-number">
                            {{components[group.components[variant.name]].sentenceCount.toLocaleString()}}
                        </td>
                        <td class="is-number" *ngIf="showWordCount">
                            {{components[group.components[variant.name]].wordCount.toLocaleString()}}
                        </td>
                    </ng-container>
                    <th class="is-number">
                        {{totalSentenceCountByGroup[group.key]}}
                    </th>
                    <th class="is-number" *ngIf="showWordCount">
                        {{totalWordCountByGroup[group.key]}}
                    </th>
                </tr>
            </tbody>
        </ng-template>

        <tfoot>
            <tr>
                <td [attr.colspan]="(variants ? variants.length : 1) + (showDescription ? 1 : 0)">
                    &nbsp;
                </td>
                <ng-container *ngFor="let variant of (variants || [])">
                    <td class="is-number">{{totalSentenceCountByVariant[variant.name]}}</td>
                    <td class="is-number" *ngIf="showWordCount">{{totalWordCountByVariant[variant.name]}}</td>
                </ng-container>
                <td class="is-number has-text-weight-bold">{{totalSentenceCount}}</td>
                <td class="is-number has-text-weight-bold" *ngIf="showWordCount">{{totalWordCount}}</td>
            </tr>
        </tfoot>
    </table>
</div>
