<div class="pageloader is-black" [ngClass]="{'is-active':showLoader}"><span class="title">Loading tree...</span></div>
<div #output>
    <div #inline class="tree-visualizer tv" *ngIf="display != 'fullscreen'">
        <div class="tv-error">
            <p></p>
        </div>
        <div class="tv-content-wrapper">
            <div class="tv-toolbar-wrapper" *ngIf="fullScreenButton || sentence">
                <div class="tv-sentence-wrapper">
                    <fa-icon [icon]="faCommentDots" aria-hidden="true"></fa-icon>
                    <span></span>
                </div>
                <button class="tv-show-fs" grtBalloonPosition="left" grtBalloon="Open the tree in full screen mode" type="button" *ngIf="fullScreenButton">
                    <fa-icon [icon]="faArrowsAlt" aria-hidden="true"></fa-icon>
                    <span class="sr-only">Open in fullscreen</span>
                </button>
            </div>
            <ng-container *ngTemplateOutlet="metadataTemplate"></ng-container>
            <div class="tv-tree" #tree>
            </div>

            <aside class="tv-tooltip" style="display: none">
                <ul></ul>
                <button grtBalloonPosition="up" grtBalloon="Close this tooltip" type="button">
                    <fa-icon [icon]="faTimes" aria-hidden="true"></fa-icon>
                    <span class="sr-only">Close</span>
                </button>
                <span class="arrow"></span>
            </aside>


        </div>
    </div>
    <div class="tree-visualizer-fs tv tv-fs">
        <div class="tv-error">
            <p></p>
        </div>
        <div class="tv-content-wrapper">
            <div class="tv-toolbar-wrapper level is-marginless">
                <div class="tv-sentence-wrapper level-left">
                    <fa-icon [icon]="faCommentDots" aria-hidden="true"></fa-icon>
                    <span class="level-item"></span>
                </div>
                <div class="level-item">
                    <nav class="tv-navigation-wrapper">
                        <button class="tv-prev-tree" type="button" grtBalloonPosition="down" grtBalloon="Show previous tree">
                            <fa-icon [icon]="faChevronLeft" aria-hidden="true"></fa-icon>
                            <span class="sr-only">Previous tree</span>
                        </button>
                        <button class="tv-next-tree" type="button" grtBalloonPosition="down" grtBalloon="Show next tree">
                            <fa-icon [icon]="faChevronRight" aria-hidden="true"></fa-icon>
                            <span class="sr-only">Next tree</span>
                        </button>
                    </nav>
                </div>
                <div class="tv-zoom-wrapper level-right">
                    <div class="level-item">
                        <div class="field">
                            <p class="control">
                                <a *ngIf="url;else downloadButton" class="button" [href]="url" target="_blank" grtBalloonPosition="down" grtBalloon="Show XML">
                                    <span class="icon is-large">
                                        <fa-icon [icon]="faFileCode" aria-hidden="true"></fa-icon>
                                    </span>
                                    <span>Show XML</span>
                                </a>
                                <ng-template #downloadButton>
                                    <span class="button" (click)="downloadXml()" grtBalloonPosition="down" grtBalloon="Download XML" rel="button">
                                        <span class="icon is-large">
                                            <fa-icon [icon]="faFileCode" aria-hidden="true"></fa-icon>
                                        </span>
                                        <span>Download XML</span>
                                    </span>
                                </ng-template>
                            </p>
                        </div>
                    </div>
                    <div class="level-item">
                        <div class="field is-grouped">
                            <div class="tv-zoom-opts field has-addons">
                                <p class="control">
                                    <button class="tv-zoom-out button" type="button">
                                        <fa-icon [icon]="faSearchMinus" aria-hidden="true"></fa-icon>
                                        <span class="sr-only">Zoom out</span>
                                    </button>
                                </p>
                                <p class="control">
                                    <button class="tv-zoom-default button is-primary" type="button">Default</button>
                                </p>
                                <p class="control">
                                    <button class="tv-zoom-in button" type="button">
                                        <fa-icon [icon]="faSearchPlus" aria-hidden="true"></fa-icon>
                                        <span class="sr-only">Zoom in</span>
                                    </button>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="level-item">
                        <div class="field">
                            <p class="control">
                                <button grtBalloonPosition="down-right" grtBalloon="Close fullscreen mode" type="button" class="tv-close-fs button is-danger">
                                    <fa-icon [icon]="faTimes" aria-hidden="true"></fa-icon>
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <ng-container *ngTemplateOutlet="metadataTemplate"></ng-container>
            <div class="tv-tree" #tree>
            </div>
            <aside class="tv-tooltip" style="display: none">
                <ul></ul>
                <button grtBalloonPosition="up" grtBalloon="Close this tooltip" type="button">
                    <fa-icon [icon]="faTimes" aria-hidden="true"></fa-icon>
                    <span class="sr-only">Close</span>
                </button>
                <span class="arrow"></span>
            </aside>

        </div>
    </div>
</div>
<ng-template #metadataTemplate>
    <ng-container *ngIf="metadata">
        <div class="metadata">
            <div class="card" #metadataCard>
                <table class="table">
                    <tr *ngFor="let item of metadata">
                        <th>{{item.name}}</th>
                        <td [innerHTML]="item.value"></td>
                    </tr>
                </table>
            </div>
        </div>
    </ng-container>
</ng-template>
