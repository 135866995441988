<h2 class="subtitle" grtSubtitle>
    Example Sentence
</h2>
<form (submit)="next.next()">
    <div class="columns">
        <div class="column is-half">
            <div class="field has-addons">
                <div class="control is-expanded">
                    <input type="text" class="input" placeholder="Please give a sentence" [value]="inputSentence"
                        (keyup)="inputChanges($event)" spellcheck="true" lang="nl" [ngClass]="{'is-danger':warning}">
                </div>
                <div class="control">
                    <button type="submit" class="button" [ngClass]="{'is-danger':warning, 'is-primary': !warning}">Parse</button>
                </div>
            </div>
        </div>
    </div>
</form>
